import React from "react";
import { firebaseLog } from "../../utils/analytics";
import { IconFacebook, IconInst, IconTelegram, IconWhatsapp } from "./icons";

const SocialList = () => {
  const handleClick = (content_type) =>
    firebaseLog("contact", { content_type });
  return (
    <div className="social-list">
      <a
        href="https://www.facebook.com/EasyGet.Express.Delivery"
        target="_blank"
        rel="noopener noreferrer"
        className="social-item"
        aria-label="facebook"
        onClick={() => handleClick("fb")}
      >
        <IconFacebook />
      </a>
      <a
        href="https://www.instagram.com/easyget_usa/"
        target="_blank"
        rel="noopener noreferrer"
        className="social-item"
        aria-label="instagram"
        onClick={() => handleClick("inst")}
      >
        <IconInst />
      </a>
      <a
        href="https://telegram.me/easyget_usa"
        target="_blank"
        rel="noopener noreferrer"
        className="social-item"
        aria-label="telegram"
        onClick={() => handleClick("telegram")}
      >
        <IconTelegram />
      </a>
      <a
        href="https://wa.me/13055048599"
        target="_blank"
        rel="noopener noreferrer"
        className="social-item"
        aria-label="whatsapp"
        onClick={() => handleClick("whatsapp")}
      >
        <IconWhatsapp />
      </a>
    </div>
  );
};

export default SocialList;
