import React from 'react'
import DownloadApp from './DownloadApp'

import mainlogo from '../img/main-logo.svg'
import SocialList from './Common/SocialList'
import { Link } from 'gatsby'

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer">
        <div className="container">
          <div className="footer__top">
            <div className="footer__social">
              <img src={mainlogo} alt="A main logo" className="footer__logo" />
              <SocialList />
            </div>
            <div className="footer__about">
              <div className="text">
              EasyGet is a new generation mobile app. Now shopping in Chinese marketplaces become more convenient and faster. Forget about tedious ordering procedures - just download the link to the desired product in the app or specify our warehouse in China to receive the order
              </div>
            </div>
            <div className="footer__download">
              <DownloadApp dark />
            </div>
          </div>
          <div className="footer__bottom">
            <div className="footer__copyright">
              © 2020 EASYGET
            </div>
            <div className="footer__policy-list">
              <Link to="/obrobka-dannih">Terms and conditions</Link>
              <Link to="/kliyentam">Public contract</Link>
              <Link to="/zaboroneni-tovary">Prohibited goods</Link>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
