import React, { useState } from "react";

import chat from "../img/Chatbubbles.svg";
import close from "../img/i-close-w.svg";
import fb from "../img/support-fb.svg";
import inst from "../img/support-inst.svg";
import tg from "../img/support-tg.svg";
import wtsapp from "../img/support-wtsapp.svg";

const Support = () => {
  const [active, setActive] = useState(false);
  return (
    <div className={`support ${active ? "active" : ""}`}>
      <div className="support__trigger">
        <a
          href="https://www.facebook.com/EasyGet.Express.Delivery"
          target="_blank"
          rel="noopener noreferrer"
          className="support__item support__item--fb"
        >
          <img src={fb} alt="Facebook Icon" />
        </a>
        <a
          href="https://www.instagram.com/easyget_usa/"
          target="_blank"
          rel="noopener noreferrer"
          className="support__item support__item--inst"
        >
          <img src={inst} alt="Instagram Icon" />
        </a>
        <a
          href="https://telegram.me/easyget_usa"
          target="_blank"
          rel="noopener noreferrer"
          className="support__item support__item--tg"
        >
          <img src={tg} alt="Telegram Icon" />
        </a>
        <a
          href="https://wa.me/13055048599"
          target="_blank"
          rel="noopener noreferrer"
          className="support__item support__item--wtsapp"
        >
          <img src={wtsapp} alt="Whatsapp Icon" />
        </a>
        <div
          className="support__trigger-icon"
          onClick={() => setActive(!active)}
          onKeyPress={() => setActive(!active)}
          role="button"
          tabIndex={0}
        >
          <img className="support__trigger-chat" src={chat} alt="Chat Icon" />
          <img
            className="support__trigger-close"
            src={close}
            alt="Close Icon"
          />
        </div>
        <div className="support__trigger-name">Support</div>
      </div>
    </div>
  );
};

export default Support;
